import React, { useState, useEffect } from 'react';
import './App.css';
import { useNavigate } from "react-router-dom";
/* Importing font */
const styles = `
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
/* Apply background color to the entire page */
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #282c34;
}

/* App styles */
.App {
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.App-header {
  background-color: #282c34;
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.moto {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  padding: 1em;
  background-color: #282c34;
  color: white;
  font-size: 1.2em;
}

.moto div {
  margin: 0 1em; /* Add some space between the elements */
}

.from_to {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  padding: 1em;
  background-color: #2B303A;
  color: white;
  font-size: 1.2em;
  border-radius: 10px; /* Adjust the radius for roundness */
}

/* New styles for buttons */
.button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

button {
  font-family: 'Open Sans', sans-serif;
  background-color: #61dafb;
  color: #282c34;
  padding: 10px 20px;
  margin: 0 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #21a1f1;
}


/* Media query for small screens */
@media (max-width: 600px) {
  .button-container {
    flex-direction: column;
    align-items: center;
  }

  .from_to {
    margin-top: 20px;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .moto{
   flex-direction: column;
  }
}
`;

function App() {
  const navigate = useNavigate();
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 600);
    };
    window.addEventListener('resize', handleResize);
    handleResize(); // Set initial state based on screen size
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <div className="App">
      <style>{styles}</style>
      <header className="App-header">
        <div>
          <div style={{ textAlign: 'center', marginBottom: '15%' }}>
            <i>
              <span>
                <span className="orion-editor" style={{ fontFamily: 'Playfair Display', color: 'white', fontSize: '1.5em' }}>
                  Orion the editor
                  <sup>
                    <span style={{fontSize: '0.4em', color : 'black', fontFamily: 'Open Sans' , backgroundColor: 'white', margin: '10px',paddingLeft: '3px', paddingRight: '3px'}}><b>Invite only</b></span>
                  </sup>
                  </span>
              </span>
            </i>
          </div>
        </div>
      </header>
      <div style={{fontSize: '2em', fontFamily: 'Playfair Display', color :'white'}}>
          <span style={{fontSize: '0.8em'}}>From a topic to a entire edited</span> 
          <span style={{fontFamily: 'oswald' , fontSize: '1em'}}> Youtube video </span>
          using <span>AI</span> <span style={{color : 'red'}}>.</span>
      </div>
      <div className="moto">
        <div className="button-container" style={{marginTop: '20px', display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
          <button style={{ 
            fontFamily: "'Oswald', sans-serif", 
            backgroundColor: 'white', 
            color: '#282c34', 
            padding: '10px 20px', 
            margin: '10px 0', // Adjust margin for vertical spacing
            border: 'none', 
            borderRadius: '5px', 
            cursor: 'pointer', 
            fontSize: '1.3em',
            transition: 'background-color 0.3s ease' 
          }}
          onClick={() => navigate('/Invitation')}
          
          >Get Invitation Code</button>

          <button style={{ 
            fontFamily: "'Oswald', sans-serif", 
            backgroundColor: '#3963B7', 
            color: '#282c34', 
            padding: '10px 20px', 
            margin: '10px 0', // Adjust margin for vertical spacing
            border: 'none', 
            borderRadius: '5px', 
            cursor: 'pointer', 
            fontSize: '1.3em',
            transition: 'background-color 0.3s ease' 
          }}
          
          onClick={() => navigate('/')}
          
          >Use Orion.</button>
        </div>

        <div className='from_to'>
          <div>
            <span style={{ fontSize: '1.1em', fontFamily: 'Playfair Display' }}><i>Napoleon Bonaparte</i></span>
          </div>
          <div style={{ margin: isSmallScreen ? '10px 0' : '0 0.5em', fontSize: '1.2em' }}>
            {isSmallScreen ? '⬇️' : '➡️'}
          </div>
          <iframe width="420" height="315"
            src="https://www.youtube.com/embed/xPhlLj5fOew?autoplay=1&mute=1">
          </iframe>
        </div>
      </div>

        <div>
          <div style={{marginTop: '3%'}}><span style={{fontSize: '2em', fontFamily: 'Playfair Display', color :'white', fontStyle: 'italic'}}>What using Orion is like :</span></div>
          <iframe style = {{marginTop: '2%'}} width="420" height="315"
            src="https://www.youtube.com/embed/t2SUZRN3SB4?autoplay=0&mute=0">
          </iframe>
        </div>
    </div>
  );
}

export default App;
