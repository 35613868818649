import React from 'react';
import './App.css';

/* Importing font */

const styles = `
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&display=swap');
/* Apply background color to the entire page */
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #282c34;
}

/* App styles */
.App {
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.App-header {
  background-color: #282c34;
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.moto {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  padding: 1em;
  background-color: #282c34;
  color: white;
  font-size: 1.2em;
}

.moto div {
  margin: 0 1em; /* Add some space between the elements */
}

.from_to {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  padding: 1em;
  background-color: white;
  color: white;
  font-size: 1.2em;
  width: 100%;
  max-width: 800px; /* Optional: to limit width on larger screens */
  border-radius: 10px; /* Adjust the radius for roundness */
}

/* New styles for buttons */
.button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

button {
  font-family: 'Open Sans', sans-serif;
  background-color: #61dafb;
  color: #282c34;
  padding: 10px 20px;
  margin: 0 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #21a1f1;
}


/* Media query for small screens */
@media (max-width: 600px) {
  .button-container {
    flex-direction: column;
    align-items: center;
  }

  .from_to {
    margin-top: 20px;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 90%; /* Adjust width to 90% on small screens */
  }
  .moto{
   flex-direction: column;
  }
}
`;


function Invitation() {
  return (
    <div className="App">
      <style>{styles}</style>
      <header className="App-header">
        <div>
          <div style={{ textAlign: 'center', marginBottom: '15%' }}>
            <i>
              <span>
                <span className="orion-editor" style={{ fontFamily: 'Playfair Display', color: 'white', fontSize: '1.5em' }}>
                  Orion the editor
                  <sup>
                    <span style={{fontSize: '0.4em', color : 'black', fontFamily: 'Open Sans' , backgroundColor: 'white', margin: '10px',paddingLeft: '3px', paddingRight: '3px'}}><b>Invite only</b></span>
                  </sup>
                  </span>
              </span>
            </i>
          </div>
        </div>
      </header>
      <div style={{fontSize: '2em', fontFamily: 'Playfair Display', color :'white'}}>
          <span style={{fontSize: '0.8em'}}>Invitation Form</span> 
          <span style={{color : 'red'}}> .</span>
      </div>
      <div className="moto">
        <div className='from_to'>
          <div style={{color: 'black', textAlign: 'left', fontSize: '0.7em'}}>
    <div>Dear Creator,</div>
    <br/>

    <div>
      We’re thrilled to invite you to be among the first to experience Orion the Editor, a powerful new platform designed for <b>YouTube Video Automation.</b><br/>
      <br/>
      With Orion, you can speed up your YouTube video production while maintaining both accuracy and quality, all while maximizing the amount of work not done.<br/>
      <br/>
      Get access to orion by filling out the Google form below.
      <br/>
      <br/>
      The price is $30, which allows you to create 30 videos, each up to 7 minutes long (approximately 1,050 words per script).<br/>
      <br/>
      You don't have to worry about monthly credit card bills, as we don’t require credit card information. We use direct bank transfers for payment and activation.<br/>
      <br/>
      Before purchasing Orion, you’ll receive a two-video trial on the email you provide.<br/>
      <br/>
      We look forward to seeing what you create. We're constantly improving Orion’s editing capabilities.<br/>
      <br/>
      The Creator of Orion,
      <br/>
      Dikshant Shah
      <br/>
    </div>
          </div>

        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScigvADAoCWjbog6afPKz48IzIMzkJs6KO6CqX0eLJj0Dg01A/viewform?embedded=true" 
        width="100%" 
        height="600" 
        frameBorder="0" 
        marginHeight="0" 
        marginWidth="0" style={{marginTop: '5%', border: 'none'}}>Loading…</iframe>

        </div>
      </div>

    </div>
  );
}

export default Invitation;
